<template>
  <div class="ma-4">
    <v-card class="mb-2">
        <v-card-title>
            Available Rides
        </v-card-title>
        <v-card-text>
          This page is for students who are looking for a church ride. All available rides will show up here.
        </v-card-text>
    </v-card>
    <v-card v-if="hasData" class="mb-2">
      <available-rides-table :table-config="config" @submit="registerForRide" @hasNoData="hasData = false"></available-rides-table>
    </v-card>
    <v-card v-if="!hasData"  class="mb-2">
      <v-card-title>
        There's nothing here!
      </v-card-title>
      <v-card-subtitle>
        No rides are currently scheduled.
      </v-card-subtitle>
      <v-card-actions>
        <v-btn to="/ride-share/">Back to Dashboard</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import router from '../../router'

export default {
  components: {
    availableRidesTable: () => import('@/components/ride-share/availableRidesTable')
  },
  setup (props, { root }) {
    const hasData = ref(true)
    function registerForRide (ride) {
      let data = ride[0]
      let id = data._id
      let riderEmail = root.$store.state.user.email

      if (data.seatsAvailable <= 0) {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error: That ride is full!' }
        root.$store.dispatch('main/snackbar', snackbar)
        return
      }

      root.$feathers.service('/ride-share/available-rides').patch(id, { $push: { registeredRiders: { email: riderEmail } }, seatsAvailable: data.seatsAvailable }).then(() => {
        let snackbar = { active: true, color: 'success', timeout: 6000, text: 'Successfully registered for ride!' }
        root.$store.dispatch('main/snackbar', snackbar)
        // go back to dashboard
        router.push({ path: '/ride-share/ride-share-dashboard' })
      })
        .catch((err) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: err.message }
          root.$store.dispatch('main/snackbar', snackbar)
        })
    }

    return {
      hasData,
      config: {
        submitButtonLabel: 'Register for Ride',
        isDriver: false,
        isRider: false,
        showFullRides: false,
        showFilters: true,
        showDetailButton: false
      },
      registerForRide
    }
  }
}
</script>
